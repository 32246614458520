import { Link } from "gatsby"
import React, { useState } from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = ({ page }) => {
  console.log("HEADER PAGE PROP", page)

  // Page prop was undefined, forget about members navbar for now #TODO

  // return page === "members" ? (
  //   <Navbar className="navbar-members" variant="dark">
  //     <Nav>
  //       <a href="https://lightschools.org" className="nav-link">
  //         HOME
  //       </a>
  //     </Nav>
  //   </Navbar>
  // ) : (
  return (
    <Navbar className="navbarMain" expand="lg" variant="dark">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/" className="nav-link">
            HOME
          </Link>
          <NavDropdown title="LIGHTSCHOOLS" id="basic-nav-dropdown">
            <Link to="/how-it-works" className="dropdown-item">
              How it works
            </Link>
            <AnchorLink to="/how-it-works#principles" className="dropdown-item">
              Principles
            </AnchorLink>
            <AnchorLink to="/how-it-works#what-we-do" className="dropdown-item">
              What we do
            </AnchorLink>
          </NavDropdown>
          <Link to="/contribute" className="nav-link">
            CONTRIBUTE
          </Link>
          <Link to="/academy" className="nav-link">
            ACADEMY
          </Link>
          <Link to="/events" className="nav-link">
            EVENTS
          </Link>
          <NavDropdown title="ABOUT" id="basic-nav-dropdown2">
            <Link to="/about" className="dropdown-item">
              Foundation
            </Link>
            <AnchorLink to="/about#team" className="dropdown-item">
              Team
            </AnchorLink>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    // )
  )
}

export default Header
