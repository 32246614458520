import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {
  Col,
  Row,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap"
const Footer = ({ page }) => {
  const [EMAIL, setEMAIL] = useState(undefined)
  return page === "members" ? (
    <footer className="footer">
      <Row className="flex flex-row justify-content-center py-3 mx-5">
        <Col xs={12}>
          <h3 className="text-center">
            <a href="https://lightschools.org">Home</a>
          </h3>
        </Col>
      </Row>
    </footer>
  ) : (
    <footer className="footer">
      <Row className="flex flex-row justify-content-center py-3 mx-5">
        <Col xs={12} sm={6} lg={3}>
          <h3>Menu</h3>
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/how-it-works">LIGHTSCHOOLS</Link>
            </li>
            <li>
              <Link to="/contribute">CONTRIBUTE</Link>
            </li>
            <li>
              <Link to="/academy">ACADEMY</Link>
            </li>
            <li>
              <Link to="/events">EVENTS</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <h3>Links</h3>
          <ul>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
            <li>
              <a href="http://members.lightschools.org">MEMBERS</a>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <h3>Newsletter</h3>
          <Form
            method="post"
            action="https://t91884b07.emailsys1a.net/122/3583/9f27e5ae65/subscribe/form.html"
          >
            {/* <InputGroup>
            </InputGroup> */}
            <InputGroup className="mb-4" style={{ maxWidth: "500px" }}>
              <FormControl
                name="rm_email"
                id="rm_email"
                type="text"
                value=""
                tabindex="-1"
                style={{
                  position: "absolute",
                  zIndex: "-100",
                  left: "-6000px",
                }}
              ></FormControl>
              <FormControl
                placeholder="enter your email address"
                aria-label="enter your email address"
                aria-describedby="email address"
                name="email"
                value={EMAIL}
                onChange={e => setEMAIL(e.target.value)}
              />
              <InputGroup.Append>
                <Button type="submit">SIGN UP</Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
          <div className="social">
            <a href="https://facebook.com/Lightschools" target="_blank">
              <img src={"images/social/facebook.svg"} className="social" />
            </a>
            <a href="https://instagram.com/lightschools13" target="_blank">
              <img src={"images/social/instagram.svg"} className="social" />
            </a>
            <a href="https://linkedIn.com/company/lightschools" target="_blank">
              <img src={"images/social/linkedIn.svg"} className="social" />
            </a>
            <a href="https://twitter.com/Lightschools13" target="_blank">
              <img src={"images/social/twitter.svg"} className="social" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC6DmZ615o2VqKI59dWBn0ug/about?disable_polymer=true"
              target="_blank"
            >
              <img src={"images/social/youtube.svg"} className="social" />
            </a>
          </div>
        </Col>
        <Col xs={12} className="align-self-end mt-3">
          <p style={{ color: "#b5b8d7ff", fontSize: "1rem", float: "right" }}>
            Lightschools © {new Date().getFullYear()}
          </p>
        </Col>
      </Row>
    </footer>
  )
}
export default Footer
